import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Form, Typography, notification, Button } from "antd";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";

import notificationErrors from "../../../../providers/notificationErrors";
import validateRules from "../../../../providers/validateRules";
import ModalAuthPassword from "../components/ModalAuthPassword";

import ApplicantFormParentInfo from "./ApplicantFormParentInfo";

export default function ApplicantFormFamilyProfile(props) {
	const { formDisabled, profile_id, action } = props;

	const params = useParams();
	const [form] = Form.useForm();
	const [formData, setFormData] = useState(null);

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const { data: dataRegions } = GET(`api/region`, "region");
	const { data: dataProvinces } = GET(`api/province`, "province");
	const { data: dataMunicipalities } = GET(`api/municipality`, "municipality");

	const { data: dataBarangays } = GET(`api/barangay`, "barangay");

	const [provinceList, setProvinceList] = useState([]);
	const [municipalityList, setMunicipalityList] = useState([]);
	const [barangayList, setBarangayList] = useState([]);

	const { refetch: refetchFamilyProfile } = GET(
		`api/student_exams/${profile_id}`,
		["student_exams", "check_user_permission"],
		(res) => {
			if (res.data) {
				let data = res.data;

				let number_of_brothers = data.number_of_brothers;
				let number_of_sisters = data.number_of_sisters;
				let birth_rank = data.birth_rank;

				// Address List
				let address = null;
				let zip_code = null;

				let barangay_id = null;
				let barangay_name = null;
				let municipality_id = null;
				let municipality_name = null;

				let province_id = null;
				let region_id = null;

				if (data.profile_addresses && data.profile_addresses.length > 0) {
					let familyAddress = data.profile_addresses.find(
						(item) => item.category === "FAMILY ADDRESS"
					);

					if (familyAddress) {
						let barangayId = familyAddress.barangay_id;
						if (barangayId) {
							let barangay = dataBarangays.data.find(
								(item) => item.id === barangayId
							);
							barangay_id = barangay.id;
							barangay_name = barangay.barangay;
						}

						let municipalityId = familyAddress.city_id;
						if (municipalityId) {
							let municipality = dataMunicipalities.data.find(
								(item) => item.id === municipalityId
							);
							municipality_id = municipality.id;
							municipality_name = municipality.municipality;

							let provinceId = municipality.province_id;
							if (provinceId) {
								let province = dataProvinces.data.find(
									(item) => item.id === provinceId
								);
								province_id = province.province;

								let regionId = province.region_id;
								if (regionId) {
									let region = dataRegions.data.find(
										(item) => item.id === regionId
									);
									region_id = region.region;
								}
							}
						}
						address = familyAddress.address;
						zip_code = familyAddress.zip_code;
					}
				}

				// Parent List
				let parent_list = [{}];

				if (
					data.profile_parent_informations &&
					data.profile_parent_informations.length > 0
				) {
					parent_list = data.profile_parent_informations
						.filter(
							(item) =>
								item.category === "FAMILY PROFILE" && item.status === "1"
						)
						.map((item) => {
							// console.log("ITEESM", item);
							return {
								...item,
								// birthdate:
								// 	data.birthdate && dayjs(data.birthdate).isValid()
								// 		? dayjs(data.birthdate)
								// 		: null,
								// birthdate:
								// 	item.birthdate && dayjs(item.birthdate).isValid()
								// 		? dayjs(item.birthdate)
								// 		: null,
							};
						});
				}

				// console.log("PARENT_LIST", parent_list);

				form.setFieldsValue({
					number_of_brothers,
					number_of_sisters,
					birth_rank,

					address,
					zip_code,
					barangay_id,
					municipality_id,
					province_id,
					region_id,

					parent_list: parent_list,
					// birthdate:
					// 	parent_list.length > 0 && parent_list[0].birthdate
					// 		? dayjs(parent_list[0].birthdate).format("MM-DD-YYYY")
					// 		: null,
					// age: parent_list.length > 0 ? parent_list[0].age : null,
				});
			}
		},
		false
	);

	useEffect(() => {
		refetchFamilyProfile();
	}, [profile_id, refetchFamilyProfile]);

	const {
		mutate: mutateFamilyProfileUpdate,
		isLoading: isLoadingUpdateChanges,
	} = POST(`api/update_family_profile`, "student_exams");

	const onFinish = (auth_password) => {
		let values = formData;

		//console.log("values: ", values);

		// let parent_list = values.parent_list.map((parent) => {
		// 	let birthdate = parent.birthdate
		// 		? dayjs(parent.birthdate).format("YYYY-MM-DD")
		// 		: null;
		// 	return { ...parent, birthdate };
		// });

		let data = {
			...values,
			// parent_list,
			profile_id: parseInt(profile_id, 10),

			// Auth Password Address for Changes
			auth_password: auth_password,
		};

		//console.log("dataAAAAAAAAA: ", data);

		mutateFamilyProfileUpdate(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Family Profile Updated",
						description: res.message,
					});
					setToggleModalAuthPassword({
						open: false,
						data: null,
					});
				} else {
					notification.success({
						message: "Family Profile Updated",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<>
			<Form
				form={form}
				onFinish={onFinish}
				initialValues={{ address_list: [{}], parent_list: [{}] }}
			>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					xl={24}
					className="tab-item-parent-info"
				>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Typography.Title level={5} type="secondary" className="mt-0">
							Parent
						</Typography.Title>
					</Col>
					<ApplicantFormParentInfo
						formDisabled={formDisabled}
						form={form}
						action={action}
						profile_id={params.id}
					/>
				</Col>

				<Row gutter={[18, 0]}>
					{/* Number of Siblings */}

					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Form.Item name="birth_rank" rules={[validateRules.number]}>
							<FloatInput
								label="Birth Rank"
								placeholder="Birth Rank"
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Form.Item name="number_of_brothers" rules={[validateRules.number]}>
							<FloatInput
								label="Number of Brothers"
								placeholder="Number of Brothers"
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Form.Item name="number_of_sisters" rules={[validateRules.number]}>
							<FloatInput
								label="Number of Sisters"
								placeholder="Number of Sisters"
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={6} lg={6} xl={6}></Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="region_id"
							// rules={[validateRules.quillValidator]}
						>
							<FloatSelect
								label="Region"
								placeholder="Region"
								allowClear
								required={true}
								options={
									dataRegions &&
									dataRegions.data &&
									dataRegions.data.map((item) => ({
										value: item.id,
										label: item.region,
									}))
								}
								onChange={(e) => {
									if (e) {
										let provincesTemp = dataProvinces.data.filter(
											(f) => f.region_id === e
										);
										setProvinceList(provincesTemp);
										setMunicipalityList([]);
										setBarangayList([]);
									} else {
										setProvinceList([]);
										setMunicipalityList([]);
										setBarangayList([]);
									}
								}}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="province_id"
							//  rules={[validateRules.quillValidator]}
						>
							<FloatSelect
								label="Province"
								placeholder="Province"
								allowClear
								required={true}
								options={provinceList.map((item) => ({
									value: item.id,
									label: item.province,
								}))}
								onChange={(e) => {
									if (e) {
										let municipalityTemp = dataMunicipalities.data.filter(
											(f) => f.province_id === e
										);
										setMunicipalityList(municipalityTemp);
										setBarangayList([]);
									} else {
										setMunicipalityList([]);
										setBarangayList([]);
									}
								}}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="municipality_id"
							// rules={[validateRules.quillValidator]}
						>
							<FloatSelect
								label="Municipality"
								placeholder="Municipality"
								allowClear
								required={true}
								options={municipalityList.map((item) => ({
									value: item.id,
									label: item.municipality,
								}))}
								onChange={(e) => {
									if (e) {
										let barangayTemp =
											// dataBarangays &&
											// dataBarangays.data &&
											dataBarangays.data.filter((f) => f.municipality_id === e);
										setBarangayList(barangayTemp);
									} else {
										setBarangayList([]);
									}
								}}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="barangay_id"
							// rules={[validateRules.quillValidator]}
						>
							<FloatSelect
								label="Barangay"
								placeholder="Barangay"
								allowClear
								required={true}
								options={barangayList.map((item) => ({
									value: item.id,
									label: item.barangay,
								}))}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={18} xl={18}>
						<Form.Item
							name="address"
							rules={[
								// validateRules.quillValidator,
								validateRules.address,
								validateRules.trim,
								{
									min: 10,
									message: "Please enter at least 10 characters",
								},
							]}
						>
							<FloatTextArea
								label="Address"
								placeholder="Address"
								required={true}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Form.Item
							name="zip_code"
							rules={[
								validateRules.quillValidator,
								validateRules.number,
								validateRules,
							]}
						>
							<FloatInput
								label="Zip Code"
								placeholder="Zip Code"
								disabled={formDisabled}
								required={true}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button
							className="btn-main-primary mb-20 w-xs-100"
							onClick={() => {
								setFormData(form.getFieldsValue());
								setToggleModalAuthPassword({
									open: true,
								});
							}}
						>
							Change
						</Button>
					</Col>
				</Row>

				{/* Modal */}
				<ModalAuthPassword
					toggleModalAuthPassword={toggleModalAuthPassword}
					setToggleModalAuthPassword={setToggleModalAuthPassword}
					onFinish2={onFinish}
					profile_id={profile_id}
					isLoadingUpdateChanges={isLoadingUpdateChanges}
				/>
			</Form>
		</>
	);
}
