import { useEffect } from "react";
import { Modal, Button, Form, notification } from "antd";

import { POST } from "../../../../providers/useAxiosQuery";
import FloatInputPassword from "../../../../providers/FloatInputPassword";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";

export default function ModalAuthPassword(props) {
	const {
		toggleModalAuthPassword,
		setToggleModalAuthPassword,
		onFinish2,
	} = props;
	const [form] = Form.useForm();

	const {
		mutate: mutateMultipleApplicantAuth,
		isLoading: isLoadingMultipleApplicantAuth,
	} = POST(`api/applicant_auth`, "student_exams_list");

	const onFinish = (values) => {
		let newValues = {};

		if (
			toggleModalAuthPassword.tableFilter &&
			toggleModalAuthPassword.tableFilter.tabActive ===
				"Payment Approval List View"
		) {
			newValues =
				toggleModalAuthPassword.data &&
				toggleModalAuthPassword.data.map((data) => ({
					id: data.id,
					fullname: data.fullname,
					or_number: data.or_number,
					exam_schedule_id: data.exam_schedule_id,
					exam_category_id: data.exam_category_id,
				}));

			let data = {
				...values,
				newValues: newValues,
				from:
					toggleModalAuthPassword &&
					toggleModalAuthPassword.tableFilter.tabActive,
				link_origin: window.location.origin,
			};

			mutateMultipleApplicantAuth(data, {
				onSuccess: (res) => {
					notification.success({
						message: "Success",
						description: res.message,
					});
					setToggleModalAuthPassword({
						open: false,
						data: null,
					});
					form.resetFields();
				},
				onError: (err) => {
					notificationErrors(err);
				},
			});
		} else if (
			toggleModalAuthPassword.tableFilter &&
			toggleModalAuthPassword.tableFilter.tabActive === "Exam Status List View"
		) {
			newValues =
				toggleModalAuthPassword.data &&
				toggleModalAuthPassword.data.map((data) => ({
					id: data.id,
					fullname: data.fullname ?? null,
					exam_sheet_number: data.exam_sheet_number ?? null,
					exam_status: data.exam_status ?? null,
				}));

			let data = {
				...values,
				newValues: newValues,
				from:
					toggleModalAuthPassword &&
					toggleModalAuthPassword.tableFilter.tabActive,
				link_origin: window.location.origin,
			};

			// console.log("newValues Test: ", newValues);

			mutateMultipleApplicantAuth(data, {
				onSuccess: (data) => {
					notification.success({
						message: "Success",
						description: data.message,
					});
					setToggleModalAuthPassword({
						open: false,
						data: null,
					});
					form.resetFields();
				},
				onError: (error) => {
					notificationErrors(error);
				},
			});
		} else if (
			toggleModalAuthPassword.tableFilter &&
			toggleModalAuthPassword.tableFilter.tabActive === "Exam Result List View"
		) {
			// console.log("toggleModalAuthPassword: ", toggleModalAuthPassword);

			let data = new FormData();

			data.append(
				"exam_category_id",
				parseInt(
					toggleModalAuthPassword.data &&
						toggleModalAuthPassword.data.exam_category_id,
					10
				)
			);

			if (
				toggleModalAuthPassword.data &&
				toggleModalAuthPassword.data.file_excel &&
				toggleModalAuthPassword.data.file_excel[0] &&
				toggleModalAuthPassword.data.file_excel[0].originFileObj
			) {
				data.append(
					"file_excel",
					toggleModalAuthPassword.data &&
						toggleModalAuthPassword.data.file_excel[0].originFileObj
				);
			}
			data.append("from", "Exam Result List View");
			data.append("link_origin", window.location.origin);
			data.append("auth_password", values.auth_password);

			mutateMultipleApplicantAuth(data, {
				onSuccess: (data) => {
					notification.success({
						message: "Success",
						description: data.message,
					});
					setToggleModalAuthPassword({
						open: false,
						data: null,
					});
					form.resetFields();
				},
				onError: (err) => {
					notificationErrors(err);
				},
			});
		} else {
			onFinish2(values.auth_password);
		}
	};

	useEffect(() => {
		if (toggleModalAuthPassword.open) {
			let data = { ...toggleModalAuthPassword.data };

			form.setFieldsValue(data);

			// console.log("data ModalAuthPassword: ", data);
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalAuthPassword]);

	return (
		<Modal
			title="Confirm Changes"
			open={toggleModalAuthPassword.open}
			onCancel={() => {
				setToggleModalAuthPassword({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalAuthPassword({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					htmlType="submit"
					type="primary"
					key={2}
					loading={isLoadingMultipleApplicantAuth}
					onClick={() => {
						form.submit();
					}}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="auth_password" rules={[validateRules.quillValidator]}>
					<FloatInputPassword
						label="Password"
						placeholder="Password"
						required={true}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
