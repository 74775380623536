import { useState } from "react";
import {
	Row,
	Button,
	Col,
	Table,
	Popconfirm,
	notification,
	Tooltip,
	Flex,
} from "antd";
import { useLocation } from "react-router-dom";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPencil } from "@fortawesome/pro-regular-svg-icons";
import ModalFormEntranceExamSchedule from "./ModalFormEntranceExamSchedule";
import { POST } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";

export default function TableEntranceExam(props) {
	const { dataSource, setTableFilter, tableFilter } = props;

	const location = useLocation();

	const [
		toggleModalFormEntranceExamSchedule,
		setToggleModalFormEntranceExamSchedule,
	] = useState({ open: false, data: null });

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const {
		mutate: mutateDeleteExamSchedule,
		isLoading: isLoadingArchivedSchedule,
	} = POST(`api/exam_schedule_archived`, "exam_schedule_list");

	const handleSelectedArchived = () => {
		let data = { isTrash: tableFilter.isTrash, ids: selectedRowKeys };

		mutateDeleteExamSchedule(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Exam Schedule",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Exam Schedule",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useTableScrollOnTop("tblEntranceExamSchedule", location);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper">
				<Col xs={24} sm={24} md={24}>
					<div className="tbl-top-filter">
						<div style={{ display: "flex", gap: 8 }}>
							<Flex gap={10}>
								<Button
									type={tableFilter.isTrash === 0 ? "primary" : ""}
									className={`min-w-150 ${
										tableFilter.isTrash === 0 ? "active" : ""
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											isTrash: 0,
										}));
										setSelectedRowKeys([]);
									}}
								>
									Active
								</Button>

								<Button
									type={tableFilter.isTrash === 1 ? "primary" : ""}
									className={`min-w-150 ${
										tableFilter.isTrash === 1 ? "active" : ""
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											isTrash: 1,
										}));
										setSelectedRowKeys([]);
									}}
								>
									Archived
								</Button>
							</Flex>

							{selectedRowKeys.length > 0 && (
								<Popconfirm
									title={
										<>
											Are you sure you want to
											<br />
											{tableFilter.isTrash === 0 ? "archive" : "restore"} the
											selected{" "}
											{selectedRowKeys.length > 1
												? "exam schedules"
												: "exam schedule"}
											?
										</>
									}
									okText="Yes"
									cancelText="No"
									onConfirm={() => {
										handleSelectedArchived();
									}}
									disabled={isLoadingArchivedSchedule}
								>
									<Button
										name="btn_active_archive"
										loading={isLoadingArchivedSchedule}
										danger={tableFilter.isTrash === 0}
										type="primary"
										className={tableFilter.isTrash === 1 ? "btn-success" : ""}
									>
										{tableFilter.isTrash === 0 ? "ARCHIVE" : "RESTORE"} SELECTED
									</Button>
								</Popconfirm>
							)}

							<TableGlobalSearchAnimated
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>

						<div style={{ display: "flex", gap: 12 }}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24}>
					<Table
						id="tblEntranceExamSchedule"
						className="ant-table-default ant-table-striped"
						dataSource={dataSource && dataSource.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						scroll={{ x: "max-content" }}
						sticky
						rowSelection={{
							selectedRowKeys,
							onChange: (selectedRowKeys) => {
								setSelectedRowKeys(selectedRowKeys);
							},
						}}
					>
						<Table.Column
							title="Action"
							key="action"
							dataIndex="action"
							align="center"
							width={150}
							render={(text, record) => {
								return (
									<Flex justify="center">
										<Tooltip title={"Edit"}>
											<Button
												type="link"
												className="color-1"
												name="btn_edit"
												onClick={() =>
													setToggleModalFormEntranceExamSchedule({
														open: true,
														data: record,
													})
												}
											>
												<FontAwesomeIcon icon={faPencil} />
											</Button>
										</Tooltip>

										<Tooltip title={"Duplicate"}>
											<Button
												type="link"
												className="btn-main-primary mr-10"
												name="btn_duplicate"
												onClick={() => {
													const duplicatedRowData = {
														...record,
													};
													delete duplicatedRowData.id;
													setToggleModalFormEntranceExamSchedule({
														open: true,
														data: duplicatedRowData,
													});
												}}
											>
												<FontAwesomeIcon icon={faCopy} />
											</Button>
										</Tooltip>
									</Flex>
								);
							}}
						/>

						<Table.Column
							title="School Year"
							key="school_year"
							dataIndex="school_year"
							sorter={(a, b) =>
								moment(a.sy_from).unix() - moment(b.sy_to).unix()
							}
							render={(text, record) => (
								// console.log("record: ", record),
								<span>
									{record.sy_from} - {record.sy_to}
								</span>
							)}
						/>

						<Table.Column
							title="Semester"
							key="semester"
							dataIndex="semester"
							sorter={(a, b) => a.semester.localeCompare(b.semester)}
						/>

						<Table.Column
							title="Exam Date"
							key="exam_date"
							dataIndex="exam_date"
							sorter={(a, b) =>
								moment(a.exam_date).unix() - moment(b.exam_date).unix()
							}
							render={(exam_date) => moment(exam_date).format("MMMM DD, YYYY")}
						/>

						<Table.Column
							title="Exam Time"
							key="exam_time"
							dataIndex="exam_time"
							sorter={(a, b) =>
								moment(a.time_in, "HH:mm").unix() -
								moment(b.time_in, "HH:mm").unix()
							}
							render={(text, record) => (
								<span>
									{record.time_in} {record.time_in_meridiem} - {record.time_out}{" "}
									{record.time_out_meridiem}
								</span>
							)}
						/>

						<Table.Column
							title="Slots"
							key="slots"
							dataIndex="slots"
							align="center"
							sorter={(a, b) => a.slots - b.slots}
						/>

						<Table.Column
							title="Available Slots"
							key="total_available_slot"
							dataIndex="total_available_slot"
							align="center"
							width={250}
							sorter={(a, b) => a.total_available_slot - b.total_available_slot}
							render={(text, record) => (
								<span
									style={{
										color: parseFloat(text) === 0 ? "#FF1B1B" : "inherit",
										fontStyle: parseFloat(text) === 0 ? "italic" : "inherit",
									}}
								>
									{parseFloat(text) === 0 ? "No Available Slot" : text}
								</span>
							)}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							setPaginationTotal={dataSource?.data.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper"
						/>
					</div>
				</Col>
			</Row>

			<ModalFormEntranceExamSchedule
				toggleModalFormEntranceExamSchedule={
					toggleModalFormEntranceExamSchedule
				}
				setToggleModalFormEntranceExamSchedule={
					setToggleModalFormEntranceExamSchedule
				}
				dataSource={dataSource}
			/>
		</>
	);
}
