import { useEffect, useState } from "react";
import {
	Row,
	Col,
	Table,
	Button,
	notification,
	Popconfirm,
	Tooltip,
	Tag,
	Flex,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearch,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";
import notificationErrors from "../../../../providers/notificationErrors";

export default function TableApplicant(props) {
	const { tableFilter, setTableFilter } = props;

	const navigate = useNavigate();
	const location = useLocation();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const {
		data: dataStudentExam,
		refetch: refetchStudentExam,
	} = GET(
		`api/student_exams?${new URLSearchParams(tableFilter)}`,
		"student_exams_list",
		(res) => {}
	);

	const {
		mutate: mutateDeactivateStudentSchedule,
		isLoading: isLoadingStudentExam,
	} = POST(`api/applicant_archived`, "student_exams_list");

	const handleSelectedArchived = () => {
		let data = { isTrash: tableFilter.isTrash, ids: selectedRowKeys };

		mutateDeactivateStudentSchedule(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Applicant",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Applicant",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		refetchStudentExam();

		return () => {};
	}, [tableFilter]);

	useTableScrollOnTop("tblApplicant", location.pathname);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper_applicant">
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-top-filter">
						<Flex gap={10}>
							<Flex gap={10}>
								{location.pathname.includes("walk-in") ? (
									<Button
										type="primary"
										icon={<FontAwesomeIcon icon={faPlus} />}
										onClick={() => {
											navigate(`${location.pathname}/add`);
										}}
										name="btn_add"
									>
										Add Walk-In Applicant
									</Button>
								) : null}

								<Button
									type={tableFilter.isTrash === 0 ? "primary" : ""}
									className={`min-w-150 ${
										tableFilter.isTrash === 0 ? "active" : ""
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											isTrash: 0,
										}));
										setSelectedRowKeys([]);
									}}
								>
									Active
								</Button>

								<Button
									type={tableFilter.isTrash === 1 ? "primary" : ""}
									className={`min-w-150 ${
										tableFilter.isTrash === 1 ? "active" : ""
									}`}
									onClick={() => {
										setTableFilter((ps) => ({
											...ps,
											isTrash: 1,
										}));
										setSelectedRowKeys([]);
									}}
								>
									Archived
								</Button>

								{selectedRowKeys.length > 0 && (
									<Popconfirm
										title={
											<>
												Are you sure you want to
												<br />
												{tableFilter.isTrash === 0 ? "archive" : "restore"} the
												selected{" "}
												{selectedRowKeys.length > 1
													? "applicants"
													: "applicant"}
												?
											</>
										}
										okText="Yes"
										cancelText="No"
										onConfirm={() => {
											handleSelectedArchived();
										}}
										disabled={isLoadingStudentExam}
									>
										<Button
											type="primary"
											name="btn_active_archive"
											loading={isLoadingStudentExam}
											danger={tableFilter.isTrash === 0}
											className={tableFilter.isTrash === 1 ? "btn-success" : ""}
										>
											{tableFilter.isTrash === 0 ? "ARCHIVE" : "RESTORE"}{" "}
											SELECTED
										</Button>
									</Popconfirm>
								)}
							</Flex>
						</Flex>
						<Flex gap={10}>
							<TableShowingEntriesV2 />
							<TableGlobalSearch
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</Flex>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Table
						id="tblApplicant"
						className="ant-table-default ant-table-striped"
						dataSource={dataStudentExam && dataStudentExam.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						scroll={{ x: "max-content" }}
						sticky={{
							offsetHeader: 0,
						}}
						rowSelection={{
							selectedRowKeys,
							onChange: (selectedRowKeys) => {
								setSelectedRowKeys(selectedRowKeys);
							},
						}}
					>
						{location.pathname.includes("archived") ? null : (
							<Table.Column
								title="Action"
								key="action"
								dataIndex="action"
								align="center"
								width={100}
								render={(text, record) => {
									return (
										<>
											<Tooltip title={"Edit Information"}>
												<Button
													type="link"
													className="primary-color w-auto h-auto p-0"
													onClick={() => {
														navigate(
															`/applicants/walk-in/edit/${record.profile_id}`
														);
													}}
													name="btn_edit"
												>
													<FontAwesomeIcon icon={faPencil} />
												</Button>
											</Tooltip>
										</>
									);
								}}
							/>
						)}

						<Table.Column
							title="Exam Category"
							key="exam_category_id"
							dataIndex="exam_category_id"
							sorter={(a, b) => a.exam_category_id - b.exam_category_id}
							render={(text, record) => {
								return <>{record.exam_category}</>;
							}}
							width={150}
						/>

						<Table.Column
							title="Exam Fee"
							key="exam_fee"
							dataIndex="exam_fee"
							sorter={(a, b) => a.exam_fee - b.exam_fee}
							width={100}
							render={(text, record) => {
								return (
									<>
										₱{" "}
										{record.exam_fee
											? parseFloat(record.exam_fee).toFixed(2)
											: "N/A"}
									</>
								);
							}}
						/>

						<Table.Column
							title="Payment Status"
							key="or_number"
							dataIndex="or_number"
							sorter={(a, b) => a.or_number - b.or_number}
							width={150}
							render={(text, record) => {
								return record.or_number === null ? (
									<Tag color="#f50" style={{ fontSize: "16px" }}>
										Not Paid
									</Tag>
								) : (
									<Tag color="#87d068" style={{ fontSize: "16px" }}>
										Paid
									</Tag>
								);
							}}
						/>

						<Table.Column
							title="Student Year Level"
							key="student_level"
							dataIndex="student_level"
							sorter={(a, b) => a.student_level.localeCompare(b.student_level)}
							width={200}
						/>

						<Table.Column
							title="Student Status"
							key="student_status"
							dataIndex="student_status"
							sorter={(a, b) =>
								a.student_status.localeCompare(b.student_status)
							}
							width={230}
						/>

						<Table.Column
							title="Student Name"
							key="fullname"
							dataIndex="fullname"
							sorter={(a, b) => a.fullname.localeCompare(b.fullname)}
							sortDirections={["descend", "ascend"]}
							width={250}
						/>

						<Table.Column
							title="Personal Email"
							key="personal_email"
							dataIndex="personal_email"
							sorter={(a, b) =>
								a.personal_email.localeCompare(b.personal_email)
							}
							width={250}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							setPaginationTotal={dataStudentExam?.data?.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper_applicant"
						/>
					</div>
				</Col>
			</Row>
		</>
	);
}
