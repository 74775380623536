import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row, Form, Typography, Radio, Checkbox } from "antd";

import dayjs from "dayjs";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import optionStudentStatus from "../../../../providers/optionStudentStatus";
import validateRules from "../../../../providers/validateRules";
import ModalDataConsent from "../components/ModalDataConsent";
import { GET } from "../../../../providers/useAxiosQuery";
import { capitalize } from "lodash";
import optionStrand from "../../../../providers/optionStrand";

export default function ApplicantFormExamInfo(props) {
	const {
		form,
		dataExamSchedule,
		handleStudentLevelChange,
		selectedStudentLevel,
	} = props;

	const location = useLocation();

	const [toggleModalDataConsent, setToggleModalDataConsent] = useState({
		open: false,
		data: null,
	});

	const { data: dataSchoolLevel } = GET(`api/school_level`, "school_level");

	const { data: dataSchoolName } = GET(`api/school`, "school_list");

	const { data: dataExamCategory } = GET(
		`api/exam_category`,
		"exam_category_list"
	);

	const { data: dataScholarship } = GET(`api/scholarship`, "scholarship_list");

	const { data: dataCourse } = GET(`api/course`, "course_list");

	const filteredScholarships =
		dataScholarship && dataScholarship.data
			? dataScholarship.data.filter(
					(item) => item.school_level_id === selectedStudentLevel
			  )
			: [];

	// Degree Programs Offered
	const renderCourseSelection = () => (
		<>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} className="p-0">
				<Typography.Title level={5} type="danger" className="mt-0">
					*For College Level Only
				</Typography.Title>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24} className="p-0 ml-10">
				<p className="mt-0 mb-0">
					<strong>Degree Programs Offered:</strong> Kindly select your top three
					(3) course choices.
				</p>
			</Col>

			{["first_course_id", "second_course_id", "third_course_id"].map(
				(course, index) => (
					<Col xs={24} sm={24} md={24} lg={6} xl={6} key={index}>
						<Form.Item name={course} rules={[validateRules.quillValidator]}>
							<FloatSelect
								label={`${capitalize(course.split("_")[0])} Course`}
								placeholder={`${capitalize(course.split("_")[0])} Course`}
								required={true}
								allowClear={true}
								options={[
									{ label: "Undecided", value: -1 },
									{ label: "NO CHOSEN COURSE", value: -2 },
									{ label: "Not in the list", value: -3 },
									...(dataCourse && dataCourse.data
										? dataCourse.data.map((item) => ({
												label: item.course_name,
												value: item.id,
										  }))
										: []),
								]}
							/>
						</Form.Item>
					</Col>
				)
			)}
		</>
	);

	return (
		<>
			<Row gutter={[18, 0]}>
				<>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Typography.Title level={5} className="mt-0">
							Current Academic Status
						</Typography.Title>
					</Col>

					<Col xs={24} sm={24} md={24} lg={6} xl={6}>
						<Form.Item
							name="student_level_id"
							rules={[validateRules.quillValidator]}
						>
							<FloatSelect
								label="Student Level"
								placeholder="Student Level"
								required={true}
								options={
									dataSchoolLevel && dataSchoolLevel.data
										? dataSchoolLevel.data.map((item) => ({
												label: item.school_level,
												value: item.id,
										  }))
										: // .sort((a, b) => a.label.localeCompare(b.label))
										  []
								}
								onChange={handleStudentLevelChange}
							/>
						</Form.Item>
					</Col>
				</>

				{/* Strand */}
				<Form.Item shouldUpdate noStyle>
					{() => {
						let student_level_id = form.getFieldValue("student_level_id");

						if (student_level_id === 4) {
							return (
								<Col xs={24} sm={24} md={12} lg={12} xl={12}>
									<Form.Item
										name="student_strand"
										rules={[validateRules.quillValidator]}
									>
										<FloatSelect
											label="Strand"
											placeholder="Strand"
											required={true}
											allowClear={true}
											options={optionStrand}
										/>
									</Form.Item>
								</Col>
							);
						} else {
							return null;
						}
					}}
				</Form.Item>

				{/* Current Course */}
				<Form.Item shouldUpdate noStyle>
					{() => {
						let student_level_id = form.getFieldValue("student_level_id");

						if (student_level_id === 5) {
							return (
								<>
									<Col xs={24} sm={24} md={24} lg={6} xl={6}>
										<Form.Item
											name="current_course_id"
											rules={[validateRules.quillValidator]}
										>
											<FloatSelect
												label="Current Course"
												placeholder="Current Course"
												required={true}
												allowClear={true}
												options={
													dataCourse && dataCourse.data
														? dataCourse.data.map((item) => ({
																label: item.course_name,
																value: item.id,
														  }))
														: []
												}
											/>
										</Form.Item>
									</Col>

									<Col xs={24} sm={24} md={24} lg={6} xl={6}>
										<Form.Item
											name="student_strand"
											rules={[validateRules.quillValidator]}
										>
											<FloatSelect
												label="High School Strand"
												placeholder="High School Strand"
												required={true}
												allowClear={true}
												// disabled={formDisabled}
												options={optionStrand}
											/>
										</Form.Item>
									</Col>
								</>
							);
						} else {
							return null;
						}
					}}
				</Form.Item>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Typography.Title level={5} className="mt-0">
						Preferred Exam
					</Typography.Title>
				</Col>

				{/* Exam Fee */}
				<Form.Item shouldUpdate noStyle>
					{() => {
						let exam_category_id = form.getFieldValue("exam_category_id");

						if (exam_category_id) {
							// Find the corresponding exam fee
							let exam_fee =
								dataExamCategory &&
								dataExamCategory.data.find(
									(item) => item.id === exam_category_id
								)?.exam_fee;

							return (
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={24}
									xl={24}
									className="p-0 ml-10"
								>
									<p className="mt-0 mb-0">
										<strong>
											Testing Fee:
											<span style={{ color: "#ff4d4f" }}> ₱ {exam_fee}.00</span>
										</strong>
										<em>
											Please note that the entrance exam fee must be paid within
											three days prior to the exam date to secure your spot.
											Failure to pay the fee may result in the cancellation of
											your exam reservation.
										</em>
									</p>
								</Col>
							);
						}
					}}
				</Form.Item>

				{/* Exam Schedule */}
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Form.Item
						name="exam_schedule_id"
						rules={[validateRules.quillValidator]}
					>
						<FloatSelect
							label="Exam Schedule"
							placeholder="Exam Schedule"
							required={true}
							disabled={
								location.pathname.includes("/applicants/walk-in/edit") ||
								location.pathname.includes("/applicants/walk-in/add")
									? false
									: true
							}
							options={
								dataExamSchedule && dataExamSchedule.data
									? Array.isArray(dataExamSchedule.data) // we need to check if the data is an array or an object, because the data from the API is inconsistent
										? dataExamSchedule.data.map((item) => {
												// const date = new Date(item.exam_date);
												const formattedDate = dayjs(item.exam_date).format(
													"MMMM DD, YYYY"
												);

												const label = `${item.sy_from} - ${item.sy_to} ${
													item.semester
												} | ${formattedDate} (${item.time_in +
													" " +
													item.time_in_meridiem} - ${item.time_out +
													" " +
													item.time_out_meridiem}) | ${
													item.total_available_slot
												} left`;

												return {
													label: label,
													value: item.id,
												};
										  })
										: dataExamSchedule.data.data.map((item) => {
												// const date = new Date(item.exam_date);
												const formattedDate = dayjs(item.exam_date).format(
													"MMMM DD, YYYY"
												);

												const label = `${item.sy_from} - ${item.sy_to} ${
													item.semester
												} | ${formattedDate} (${item.time_in +
													" " +
													item.time_in_meridiem} - ${item.time_out +
													" " +
													item.time_out_meridiem}) | ${
													item.total_available_slot
												} left`;

												return {
													label: label,
													value: item.id,
												};
										  })
									: []
							}
						/>
					</Form.Item>
				</Col>

				{/* Exam Category */}
				<Col xs={24} sm={24} md={24} lg={6} xl={6}>
					<Form.Item name="exam_category_id" rules={[validateRules.required]}>
						<FloatSelect
							label="Exam Category"
							placeholder="Exam Category"
							required={true}
							options={
								dataExamCategory && dataExamCategory.data
									? dataExamCategory.data.map((item) => ({
											label: item.category,
											value: item.id,
									  }))
									: []
							}
						/>
					</Form.Item>
				</Col>

				{/* Student Status */}
				<Form.Item shouldUpdate noStyle>
					{() => {
						let exam_category = form.getFieldValue("exam_category_id");

						// console.log("exam_category:", exam_category);

						let examCategory =
							dataExamCategory &&
							dataExamCategory.data.find((item) => item.id === exam_category)
								?.category;

						if (examCategory === "College") {
							return (
								<>
									<Col xs={24} sm={24} md={24} lg={6} xl={6}>
										<Form.Item
											name="student_status"
											rules={[validateRules.quillValidator]}
										>
											<FloatSelect
												label="Student Status"
												placeholder="Student Status"
												required={true}
												options={[
													...optionStudentStatus,
													{
														value: "Pursuing a Second Degree",
														label: "Pursuing a Second Degree",
													},
												]}
											/>
										</Form.Item>
									</Col>
								</>
							);
						} else if (exam_category !== "College") {
							return (
								<Col xs={24} sm={24} md={24} lg={6} xl={6}>
									<Form.Item
										name="student_status"
										rules={[validateRules.quillValidator]}
									>
										<FloatSelect
											label="Student Status"
											placeholder="Student Status"
											required={true}
											options={[...optionStudentStatus]}
										/>
									</Form.Item>
								</Col>
							);
						} else {
							return null;
						}
					}}
				</Form.Item>

				{/* Degree Programs Offered */}
				<Form.Item shouldUpdate noStyle>
					{() => {
						let exam_category = form.getFieldValue("exam_category_id");
						let student_status = form.getFieldValue("student_status");

						let examCategory =
							dataExamCategory &&
							dataExamCategory.data.find((item) => item.id === exam_category)
								?.category;

						// console.log("examCategory: ", examCategory);

						if (examCategory === "College") {
							if (student_status !== "Pursuing a Second Degree") {
								return renderCourseSelection();
							} else {
								return null;
							}
						} else if (
							examCategory === "College of Law" ||
							examCategory === "Graduate Studies"
						) {
							if (student_status !== "Pursuing a Second Degree") {
								return null;
							} else {
								return renderCourseSelection();
							}
						}
					}}
				</Form.Item>

				{/* Transferee */}
				{location.pathname.includes("add") ||
				location.pathname.includes("edit") ? (
					<Form.Item shouldUpdate noStyle>
						{() => {
							let student_status = form.getFieldValue("student_status");

							if (student_status === "Transferee") {
								return (
									<>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Col
												xs={24}
												sm={24}
												md={24}
												lg={24}
												xl={24}
												className="p-0"
											>
												<Typography.Title
													level={5}
													type="danger"
													className="mt-0"
												>
													*For Transferee Only
												</Typography.Title>
											</Col>

											<Col
												xs={24}
												sm={24}
												md={24}
												lg={24}
												xl={24}
												className="p-0"
											>
												<p className="mt-0">
													Please list all universities and/ or colleges you have
													attended and year attended. Failure to disclose
													previous attendance at another institution could
													result in academic dismissal.
												</p>
											</Col>

											<Row gutter={[12, 0]}>
												<Col xs={24} sm={24} md={12} lg={12} xl={12}>
													<Form.Item
														name={["previous_school_name"]}
														rules={[validateRules.quillValidator]}
													>
														<FloatSelect
															label="Name of School"
															placeholder="Name of School"
															allowClear
															required={true}
															options={
																dataSchoolName && dataSchoolName.data
																	? dataSchoolName.data
																			.filter(
																				(item) => item.school_level_id === 5
																			)
																			.map((item) => ({
																				value: item.school_name,
																				label: item.school_name,
																			}))
																			.sort((a, b) =>
																				a.label.localeCompare(b.label)
																			)
																	: []
															}
														/>
													</Form.Item>
												</Col>

												{/* Year Attended */}
												<Col xs={24} sm={12} md={12} lg={4} xl={4}>
													<Form.Item
														name={["previous_school_year"]}
														rules={[validateRules.quillValidator]}
													>
														<FloatDatePicker
															label="Year Attended"
															placeholder="Year Attended"
															format="YYYY"
															picker="year"
															allowClear={true}
															required={true}
														/>
													</Form.Item>
												</Col>
											</Row>

											<Col className="p-0">
												<p className="mt-0">
													Have you ever applied to FATHER SATURNINO URIOS
													UNIVERSITY before?
												</p>
											</Col>

											<Col xs={24} sm={24} md={24} lg={24} xl={24}>
												<Row gutter={[12, 0]}>
													<Col xs={24} sm={24} md={12} lg={12} xl={12}>
														<Form.Item name="applied_to_fsuu">
															<Radio.Group>
																<Radio value="Yes">Yes</Radio>
																<Radio value="No">No</Radio>
															</Radio.Group>
														</Form.Item>
													</Col>

													<Form.Item shouldUpdate noStyle>
														{() => {
															let applied_to_fsuu = form.getFieldValue(
																"applied_to_fsuu"
															);

															if (applied_to_fsuu === "Yes") {
																return (
																	<>
																		<Col xs={24} sm={24} md={4} lg={4} xl={4}>
																			<Form.Item
																				name="year_applied"
																				rules={[validateRules.quillValidator]}
																			>
																				<FloatDatePicker
																					label="Year Applied"
																					placeholder="Year Applied"
																					format="YYYY"
																					picker="year"
																					allowClear={true}
																				/>
																			</Form.Item>
																		</Col>

																		<Col
																			xs={24}
																			sm={24}
																			md={12}
																			lg={12}
																			className="p-0"
																		>
																			<p className="mt-0">Were you Accepted?</p>
																		</Col>

																		<Col
																			xs={24}
																			sm={24}
																			md={24}
																			lg={24}
																			xl={24}
																		>
																			<Row gutter={[12, 0]}>
																				<Col
																					xs={24}
																					sm={24}
																					md={12}
																					lg={12}
																					xl={12}
																				>
																					<Form.Item name="accepted_to_fsuu">
																						<Radio.Group>
																							<Radio value="Yes">Yes</Radio>
																							<Radio value="No">No</Radio>
																						</Radio.Group>
																					</Form.Item>
																				</Col>
																				<Form.Item shouldUpdate noStyle>
																					{() => {
																						let accepted_to_fsuu = form.getFieldValue(
																							"accepted_to_fsuu"
																						);

																						if (accepted_to_fsuu === "Yes") {
																							return (
																								<>
																									<Col
																										xs={24}
																										sm={24}
																										md={4}
																										lg={4}
																										xl={4}
																									>
																										<Form.Item
																											name="year_accepted"
																											rules={[
																												validateRules.quillValidator,
																											]}
																										>
																											<FloatDatePicker
																												label="Year Accepted"
																												placeholder="Year Accepted"
																												format="YYYY"
																												picker="year"
																												allowClear={true}
																											/>
																										</Form.Item>
																									</Col>
																								</>
																							);
																						}
																					}}
																				</Form.Item>
																			</Row>
																		</Col>

																		<Col
																			xs={24}
																			sm={24}
																			md={24}
																			lg={24}
																			xl={24}
																			className="mt-0"
																		>
																			<p className="mt-0">Did you attend?</p>
																		</Col>

																		<Col
																			xs={24}
																			sm={24}
																			md={24}
																			lg={24}
																			xl={24}
																		>
																			<Row gutter={[12, 0]}>
																				<Col
																					xs={24}
																					sm={24}
																					md={12}
																					lg={12}
																					xl={12}
																				>
																					<Form.Item
																						name="attended_to_fsuu"
																						className="mb-0"
																					>
																						<Radio.Group>
																							<Radio value="Yes">Yes</Radio>
																							<Radio value="No">No</Radio>
																						</Radio.Group>
																					</Form.Item>
																				</Col>

																				<Form.Item shouldUpdate noStyle>
																					{() => {
																						let attended_to_fsuu = form.getFieldValue(
																							"attended_to_fsuu"
																						);

																						if (attended_to_fsuu === "Yes") {
																							return (
																								<>
																									{" "}
																									<Col
																										xs={24}
																										sm={24}
																										md={4}
																										lg={4}
																										xl={4}
																									>
																										<Form.Item
																											name="year_attended"
																											rules={[
																												validateRules.quillValidator,
																											]}
																										>
																											<FloatDatePicker
																												label="Year Attended"
																												placeholder="Year Attended"
																												format="YYYY"
																												picker="year"
																												allowClear={true}
																											/>
																										</Form.Item>
																									</Col>
																								</>
																							);
																						}
																					}}
																				</Form.Item>
																			</Row>
																		</Col>
																	</>
																);
															} else {
																return null;
															}
														}}
													</Form.Item>
												</Row>
											</Col>
										</Col>
									</>
								);
							} else {
								return null;
							}
						}}
					</Form.Item>
				) : null}

				{/* Data Consent */}
				{!location.pathname.includes("/applicants/walk-in/edit") ? (
					<>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Typography.Title level={5} className="mt-0">
								Data Consent
							</Typography.Title>
						</Col>

						<Col xs={24} sm={24} md={24} lg={12} xl={12}>
							<Form.Item
								name="data_consent"
								rules={[
									{
										validator: (_, value) =>
											value &&
											value.includes("AgreeTerms") &&
											value.includes("AgreePrivacy")
												? Promise.resolve()
												: Promise.reject(
														new Error("Both fields are required.")
												  ),
									},
								]}
							>
								<Checkbox.Group>
									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<Checkbox value="AgreeTerms">
											I have read and agree to the website{" "}
											<Typography.Link href="" target="_blank">
												Terms and Conditions
												<Typography.Text type="danger">*</Typography.Text>
											</Typography.Link>
										</Checkbox>
									</Col>

									<Col xs={24} sm={24} md={24} lg={24} xl={24}>
										<Checkbox value="AgreePrivacy">
											I have read and agree to the website{" "}
											<Typography.Link
												href="#"
												onClick={() =>
													setToggleModalDataConsent({
														open: true,
													})
												}
											>
												Privacy Policy
												<Typography.Text type="danger">*</Typography.Text>
											</Typography.Link>
											<ModalDataConsent
												toggleModalDataConsent={toggleModalDataConsent}
												setToggleModalDataConsent={setToggleModalDataConsent}
											/>
										</Checkbox>
									</Col>
								</Checkbox.Group>
							</Form.Item>
						</Col>
					</>
				) : null}

				{/* Scholarship */}
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Typography.Title level={5} className="mt-0">
						Scholarship
					</Typography.Title>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="p-0 ml-10">
					<p>
						<Typography.Text type="danger">* </Typography.Text>Do you intent to
						apply for a scholarship?
					</p>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="ml-10">
					<Form.Item
						name="apply_scholarship"
						className="mb-0"
						rules={[validateRules.required]}
					>
						<Radio.Group>
							<Radio value="Yes">Yes</Radio>
							<Radio value="No">No</Radio>
						</Radio.Group>
					</Form.Item>
				</Col>

				{/* <Form.Item shouldUpdate noStyle>
					{() => (
						<>
							{form.getFieldValue("apply_scholarship") === "Yes" && (
								<>
									{filteredScholarships.length <= 0 ? null : (
										<>
											<Col
												xs={24}
												sm={24}
												md={24}
												lg={24}
												xl={24}
												className="p-0 ml-10"
											>
												<p>
													Here are the list of scholarship offered (Please check
													scholarship programs you are interested in). An email
													will be sent to you for further details.
												</p>
											</Col>
											<Col xs={24} sm={24} md={24} lg={24} xl={24}>
												<Form.Item name="scholarship_id">
													<Checkbox.Group
														className="checkbox-wrapper learning-disability-checkbox"
														value={scholarship ? scholarship.split(", ") : []}
													>
														<Flex vertical>
															{filteredScholarships.length <= 0 ? (
																<p>No available scholarships</p>
															) : (
																filteredScholarships.map((item) => (
																	<Checkbox value={item.id} key={item.id}>
																		<strong>{item.name}</strong> (
																		<em>
																			{item.category} | {item.benefits}
																		</em>
																		)
																	</Checkbox>
																))
															)}
														</Flex>
													</Checkbox.Group>
												</Form.Item>
											</Col>
										</>
									)}
								</>
							)}
						</>
					)}
				</Form.Item> */}
			</Row>
		</>
	);
}
