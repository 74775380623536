import { useEffect, useState } from "react";
import {
	Button,
	Col,
	Form,
	Row,
	Popconfirm,
	Checkbox,
	notification,
	Tooltip,
	Flex,
} from "antd";
import { useLocation } from "react-router-dom";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";

import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatSelect from "../../../../providers/FloatSelect";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalAuthPassword from "../components/ModalAuthPassword";

export default function ApplicantFormAddressInfo(props) {
	const { formDisabled, profile_id } = props;

	const location = useLocation();
	const [form] = Form.useForm();
	const [formData, setFormData] = useState(null);
	const [dataProvince, setDataProvince] = useState([]);
	const [dataMunicipality, setDataMunicipality] = useState([]);
	const [dataBarangay, setDataBarangay] = useState([]);

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const { data: dataRegions } = GET(
		`api/region_dropdown`,
		"region_select",
		(res) => {},
		false
	);

	const { refetch: refetchStudentAddress } = GET(
		`api/student_exams/${profile_id}`,
		["student_exams", "check_user_permission"],
		(res) => {
			if (res.data) {
				let data = res.data;

				console.log("data: ", data);

				let address_list = [{}];

				if (data.profile_addresses && data.profile_addresses.length > 0) {
					address_list = data.profile_addresses
						.filter((item) => item.category === "STUDENT ADDRESS")
						.map((item, index) => {
							console.log("item: ", item);

							// return {
							// 	...item,
							// 	barangay_id: barangay_id,
							// 	municipality_id: municipality_id,
							// 	province_id: province_id.province_id,
							// 	region_id: region_id.region_id,
							// };
						});
				}

				form.setFieldsValue({
					address_list: address_list,
				});
			}
		},
		false
	);

	useEffect(() => {
		refetchStudentAddress();
	}, [profile_id]);

	const {
		mutate: mutateStudentUpdate,
		isLoading: isLoadingUpdateChanges,
	} = POST(`api/update_student_address`, "student_exams");

	const onFinish = (auth_password) => {
		let values = formData;

		let data = {
			...values,
			profile_id: parseInt(profile_id, 10),

			// Auth Password Address for Changes
			auth_password: auth_password,
		};

		mutateStudentUpdate(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Student Profile Updated",
						description: res.message,
					});
					setToggleModalAuthPassword({
						open: false,
						data: null,
					});
				} else {
					notification.error({
						message: "Student Profile Updated",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const RenderInput = (props) => {
		const { formDisabled, name, restField, fields, remove } = props;

		const handleChangeHomeAddress = () => {
			// const { checked, value } = e.target;
			let formTemp = Array.isArray(form.getFieldValue("address_list"))
				? form.getFieldValue("address_list")
				: [];
			let thisval = formTemp[name];
			formTemp = formTemp.map((item) => ({
				...item,
				is_home_address: null,
			}));
			formTemp[name] = { ...thisval };
			form.setFieldsValue({
				address_list: formTemp,
			});
		};
		const handleChangeCurrentAddress = () => {
			// const { checked, value } = e.target;
			let formTemp = Array.isArray(form.getFieldValue("address_list"))
				? form.getFieldValue("address_list")
				: [];
			let thisval = formTemp[name];
			formTemp = formTemp.map((item) => ({
				...item,
				is_current_address: null,
			}));
			formTemp[name] = { ...thisval };
			form.setFieldsValue({
				address_list: formTemp,
			});
		};

		return (
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "region_id"]}
						rules={[validateRules.quillValidator]}
					>
						<FloatSelect
							label="Region"
							placeholder="Region"
							allowClear
							required={true}
							options={
								dataRegions && dataRegions.data
									? dataRegions.data.map((item) => ({
											value: item.id,
											label: item.region,
									  }))
									: []
							}
							onChange={(e) => {
								let provinces = [];
								if (e) {
									let region = dataRegions?.data?.find((f) => f.id === e);

									provinces = region.ref_provinces;
								}
								setDataProvince(provinces);
								setDataMunicipality([]);
								setDataBarangay([]);
								let formTemp = form.getFieldValue("address_list") || {};

								formTemp[name] = {
									...(formTemp[name] || {}),
									region_id: e,
									province_id: null,
									municipality_id: null,
									barangay_id: null,
								};
								form.setFieldsValue({
									address_list: formTemp,
								});
							}}
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Tooltip
						title={
							form.getFieldValue("address_list")?.[0]?.region_id == null
								? "Please select Region"
								: ""
						}
					>
						<Form.Item
							{...restField}
							name={[name, "province_id"]}
							rules={[validateRules.quillValidator]}
						>
							<FloatSelect
								label="Province"
								placeholder="Province"
								allowClear
								value={form.getFieldValue([
									"address_list",
									name,
									"province_id",
								])}
								required={true}
								options={
									dataProvince
										? dataProvince.map((item) => ({
												value: item.id,
												label: item.province,
										  }))
										: []
								}
								onChange={(e) => {
									let municipalities = [];
									if (e) {
										let province = dataProvince.find((f) => f.id === e);

										municipalities = province.ref_municipalities;
									}

									setDataMunicipality(municipalities);
									setDataBarangay([]);

									let formTemp = form.getFieldValue("address_list");

									formTemp[name] = {
										...(formTemp[name] || {}),
										province_id: e,
										municipality_id: null,
										barangay_id: null,
									};
									form.setFieldsValue({
										address_list: formTemp,
									});
								}}
								disabled={
									!form.getFieldValue("address_list")?.[0] ||
									form.getFieldValue("address_list")[0]?.region_id == null
								}
							/>
						</Form.Item>
					</Tooltip>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Tooltip
						title={
							form.getFieldValue("address_list")?.[0]?.province_id == null
								? "Please select Province"
								: ""
						}
					>
						<Form.Item
							{...restField}
							name={[name, "municipality_id"]}
							rules={[validateRules.quillValidator]}
						>
							<FloatSelect
								label="Municipality"
								placeholder="Municipality"
								allowClear
								required={true}
								value={form.getFieldValue([
									"address_list",
									name,
									"municipality_id",
								])}
								options={dataMunicipality.map((item) => ({
									value: item.id,
									label: item.municipality,
								}))}
								onChange={(e) => {
									let barangays = [];
									if (e) {
										let municipality = dataMunicipality.find((f) => f.id === e);

										barangays = municipality.ref_barangays;
									}

									setDataBarangay(barangays);

									let formTemp = form.getFieldValue("address_list");

									formTemp[name] = {
										...(formTemp[name] || {}),
										municipality_id: e,
										barangay_id: null,
									};
									form.setFieldsValue({
										address_list: formTemp,
									});
								}}
								disabled={
									!form.getFieldValue("address_list")?.[0] ||
									form.getFieldValue("address_list")[0]?.province_id == null
								}
							/>
						</Form.Item>
					</Tooltip>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Tooltip
						title={
							form.getFieldValue("address_list")?.[0]?.municipality_id == null
								? "Please select Municipality"
								: ""
						}
					>
						<Form.Item
							{...restField}
							name={[name, "barangay_id"]}
							rules={[validateRules.quillValidator]}
							wrapperCol={{ span: 24 }}
						>
							<FloatSelect
								label="Barangay"
								placeholder="Barangay"
								allowClear
								value={form.getFieldValue([
									"address_list",
									name,
									"barangay_id",
								])}
								required={true}
								options={dataBarangay.map((item) => ({
									value: item.id,
									label: item.barangay,
								}))}
								onChange={(e) => {
									let formTemp = form.getFieldValue("address_list");

									formTemp[name] = {
										...(formTemp[name] || {}),
										barangay_id: e,
									};
									form.setFieldsValue({
										address_list: formTemp,
									});
								}}
								disabled={
									!form.getFieldValue("address_list")?.[0] ||
									form.getFieldValue("address_list")[0]?.municipality_id == null
								}
							/>
						</Form.Item>
					</Tooltip>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div className="action">
						<div className="checkbox-group">
							<Flex>
								<Form.Item
									{...restField}
									name={[name, "is_home_address"]}
									noStyle
									valuePropName="checked"
								>
									<Checkbox value={1} onChange={handleChangeHomeAddress}>
										Home Address
									</Checkbox>
								</Form.Item>

								<Form.Item
									{...restField}
									name={[name, "is_current_address"]}
									noStyle
									valuePropName="checked"
								>
									<Checkbox value={2} onChange={handleChangeCurrentAddress}>
										Current Address
									</Checkbox>
								</Form.Item>

								<p
									style={{
										fontStyle: "italic",
										margin: "0px",
										color: "#787575",
									}}
								>
									(Please check if it's both the current address and the home
									address.)
								</p>
							</Flex>
						</div>
						{fields.length > 1 ? (
							<Popconfirm
								title="Are you sure to delete this address?"
								onConfirm={() => {
									// handleDeleteQuestion(name);
									remove(name);
								}}
								onCancel={() => {}}
								okText="Yes"
								cancelText="No"
								placement="topRight"
								okButtonProps={{
									className: "btn-main-invert",
								}}
							>
								<Button type="link" className="form-list-remove-button p-0">
									<FontAwesomeIcon icon={faTrashAlt} className="fa-lg" />
								</Button>
							</Popconfirm>
						) : null}
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.Item
						{...restField}
						name={[name, "address"]}
						rules={[
							validateRules.quillValidator,
							validateRules.address,
							validateRules.trim,
							{
								min: 5,
								message: "Please enter at least 5 characters",
							},
						]}
					>
						<FloatTextArea
							label="Address"
							placeholder="Address"
							required={true}
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>
			</Row>
		);
	};

	return location.pathname.includes("edit") ? (
		<Form
			form={form}
			onFinish={onFinish}
			initialValues={{ address_list: [{}] }}
		>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.List name="address_list">
						{(fields, { add, remove }) => (
							<Row gutter={[12, 0]}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									{fields.map(({ key, name, ...restField }, index) => (
										<div
											key={index}
											className={`${index !== 0 ? "mt-25" : ""}`}
										>
											<RenderInput
												formDisabled={formDisabled}
												form={form}
												dataRegions={dataRegions}
												name={name}
												restField={restField}
												fields={fields}
												remove={remove}
											/>
										</div>
									))}
								</Col>

								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									{location.pathname.includes("edit") ? (
										<Button
											className="btn-main-primary mb-20 w-xs-100 mr-20"
											onClick={() => {
												setFormData(form.getFieldsValue());
												setToggleModalAuthPassword({
													open: true,
												});
											}}
										>
											Change
										</Button>
									) : null}

									<Button
										type="link"
										className="btn-main-primary p-0"
										icon={<FontAwesomeIcon icon={faPlus} />}
										onClick={() => add()}
									>
										Add Another Address
									</Button>
								</Col>
								{/* Modal */}
								<ModalAuthPassword
									toggleModalAuthPassword={toggleModalAuthPassword}
									setToggleModalAuthPassword={setToggleModalAuthPassword}
									onFinish2={onFinish}
									isLoadingUpdateChanges={isLoadingUpdateChanges}
								/>
							</Row>
						)}
					</Form.List>
				</Col>
			</Row>
		</Form>
	) : (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Form.List name="address_list">
					{(fields, { add, remove }) => (
						<Row gutter={[12, 0]}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								{fields.map(({ key, name, ...restField }, index) => (
									<div key={key} className={`${index !== 0 ? "mt-25" : ""}`}>
										<RenderInput
											formDisabled={formDisabled}
											form={form}
											dataRegions={dataRegions}
											name={name}
											restField={restField}
											fields={fields}
											remove={remove}
										/>
									</div>
								))}
							</Col>

							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								{location.pathname.includes("edit") ? (
									<Button
										className="btn-main-primary w-10 mb-20 w-xs-100 mr-20"
										onClick={() => {
											setFormData(form.getFieldsValue());
											setToggleModalAuthPassword({
												open: true,
											});
										}}
									>
										Change
									</Button>
								) : null}
								<Button
									type="link"
									className="btn-main-primary p-0"
									icon={<FontAwesomeIcon icon={faPlus} />}
									onClick={() => add()}
								>
									Add Another Address
								</Button>
							</Col>
							{/* Modal */}
							<ModalAuthPassword
								toggleModalAuthPassword={toggleModalAuthPassword}
								setToggleModalAuthPassword={setToggleModalAuthPassword}
								onFinish2={onFinish}
								profile_id={profile_id}
								isLoadingUpdateChanges={isLoadingUpdateChanges}
							/>
						</Row>
					)}
				</Form.List>
			</Col>
		</Row>
	);
}
